import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { keccak256 } from 'ethers/lib/utils';
import GenesysView from './contracts/artifacts/GenesysView.json';

import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from "styled-components";
import { initWeb3Onboard } from './services'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {
  useAccountCenter,
  useConnectWallet,
  useNotifications,
  useSetChain,
  useWallets,
  useSetLocale
} from '@web3-onboard/react'
import './App.css'
import addressesWL from "./whitelist.json";
import { borderLeftColor } from '@mui/system';



let provider

const NFTcontractAddress = "0xE559038e465626207a48A9C3b2F063f95275dF3c";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{ height: '15px', borderRadius: "30px", background: "#0073ff3b" }} variant="determinate" {...props} />
      </Box>

    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};



function MintPage() {
  const [{ wallet }, connect, disconnect, updateBalances, setWalletModules] =
    useConnectWallet()
  const [notifications, customNotification, updateNotify] = useNotifications()
  const connectedWallets = useWallets()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [web3Onboard, setWeb3Onboard] = useState(null)

  const [error, setError] = useState('');
  const [errCode, setErrCode] = useState(0);
  const [data, setData] = useState({});
  const [WL, setWL] = useState(false);
  const [VIP, setVIP] = useState(false);
  const [accountCenterPosition, setAccountCenterPosition] = useState('topRight')
  const [notifyPosition, setNotifyPosition] = useState('bottomRight')
  const [locale, setLocale] = useState('en')
  const [accountCenterSize, setAccountCenterSize] = useState('normal')
  const [accountCenterExpanded, setAccountCenterExpanded] = useState(false)

  const [progress, setProgress] = React.useState(10);


  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
  }, [])

  useEffect(() => {
    console.log(notifications)
  }, [notifications])

  useEffect(() => {
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )


  }, [connectedWallets, wallet])



  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    )

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        await connect({ autoSelect: previouslyConnectedWallets[0], disableModals: true })
      }
      setWalletFromLocalStorage()
    }
  }, [web3Onboard, connect])


  useEffect(() => {
    checkWL();
    fetchData();
  }, []);






  async function checkWL() {
    if (typeof window.ethereum !== 'undefined') {
      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const { MerkleTree } = require('merkletreejs');
      const leaves = addressesWL.map(x => keccak256(x));
      const tree = new MerkleTree(leaves, keccak256, { sort: true });
      const root = tree.getHexRoot();
      const leaf = keccak256(accounts[0]);
      const proof = tree.getHexProof(leaf);
      console.log(accounts[0]);
      try {
        if (tree.verify(proof, leaf, root))
          setWL(true);
        else setWL(false);
      }
      catch (err) {
        setError(err.message);
      }
    }
  }
  async function fetchData() {
    if (typeof window.ethereum !== 'undefined') {

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(NFTcontractAddress, GenesysView.abi, provider);

      try {
        let cost;

        const totalSupply = await contract.totalSupply();
        const step = await contract.sellingStep();
        if (step == 1) {
          cost = await contract.wlSalePrice();
          document.getElementById("whitelist").classList.add("active");
          document.getElementById("public").classList.remove("active");
        }
        if (step == 2) {
          cost = await contract.publicSalePrice();
          document.getElementById("whitelist").classList.remove("active");
          document.getElementById("public").classList.add("active");
        }
        const object = { "cost": String(cost), "totalSupply": String(totalSupply), "step": String(step) }

        setData(object);

      }
      catch (err) {
        setError(err.message);
      }
    }
  }
  async function whitelistmint() {
    if (typeof window.ethereum !== 'undefined') {
      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const { MerkleTree } = require('merkletreejs');
      const contract = new ethers.Contract(NFTcontractAddress, GenesysView.abi, signer);
      const leaves = addressesWL.map(x => keccak256(x));
      const tree = new MerkleTree(leaves, keccak256, { sort: true });
      const root = tree.getHexRoot();
      const leaf = keccak256(accounts[0]);
      const proof = tree.getHexProof(leaf);
      try {
        let overrides = {
          from: accounts[0],
          value: String(data.cost * 1)
        }
        const transaction = await contract.whitelistMint(accounts[0], 1, proof, overrides);
        await transaction.wait();
        fetchData();
      }
      catch (err) {
        setError(err.message);
        handleErrorWL(err);
      }
    }
  }

  async function mint() {
    if (typeof window.ethereum !== 'undefined') {
      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(NFTcontractAddress, GenesysView.abi, signer);
      try {
        let overrides = {
          from: accounts[0],
          value: String(data.cost * 1),
        }
        const transaction = await contract.publicSaleMint(accounts[0], 1, overrides);
        await transaction.wait();
        fetchData();
      }
      catch (err) {
        setError(err.message);
        handleError(err.message);
      }
    }
  }
  async function handleError(err) {

    if (err.includes("user rejected transaction")) {
      console.log('User denied the transaction signature.');
      setErrCode(1);
      handleShow();
    }
    if (err.includes("insufficient funds")) {
      console.log('Insufficient funds');
      setErrCode(3);
      handleShow();
    }

  }
  async function handleErrorWL(err) {

    if (err.message.includes("You can only get")) {
      console.log('You are trying to mint more than the allowed amount.');
      setErrCode(2);
      handleShow();
    }
    if (err.message?.includes("user rejected transaction")) {
      console.log('User denied the transaction signature.');
      setErrCode(1);
      handleShow();
    }
    if (err.message?.includes("insufficient funds")) {
      console.log('Insufficient funds');
      setErrCode(3);
      handleShow();
    }

  }

  function runOnStart(){
    var loadscr = document.getElementById("loadscr");
    loadscr.style.animation = "moveout 1.5s ease-in-out forwards";
  }
  if (document.readyState !== "loading") {
    runOnStart();
  }
  else {
    document.addEventListener('DOMContentLoaded', function () {
      runOnStart()
    });
  }
  return (
    <main>
      <div className="loadscr" id="loadscr">
        <img src="logo192.png" alt="" />
      </div>
      <section className="main">

        <div className="main-content">
          <div className="imageleft">
            <video src="preview.mp4" autoPlay loop muted playsInline alt="nft" />
          </div>
          <div className="containerr onboard">
            <div className="phases">
              <div className="phase" id="whitelist">
                Whitelist
              </div>
              <div className="phase" id="public">
                Public
              </div>
            </div>
            <h2>GenesysView</h2>
            <div className="dates">
              <div className="date">
                Date
              </div>
              <div className="datetext">
                &nbsp;- X Dec 2022 - <br /> Whitelist - 385 MATIC <br /> Public - 445 MATIC
              </div>
            </div>

            <div>
              {!wallet && (
                <button
                  className="mintbutton"
                  onClick={() => {
                    connect()
                  }}
                >
                  CONNECT
                </button>
              )}
              {wallet && (
                <div className="minting">
                  {data.step != 0 && data.step != null ? (<>
                    <div className="cost">
                      <h2>Price</h2>
                      <h3>{data.cost / 10 ** 18} <span>MATIC</span></h3>
                    </div>

                    <div className='progress'>
                      <h3 className="minted">Total minted &nbsp;({data.totalSupply} / 300) - {Math.round((data.totalSupply * 100 / 300)) + '%'}</h3>
                      <Box sx={{ width: '100%', height: '60px' }}>
                        <LinearProgressWithLabel value={(data.totalSupply * 100 / 300)} />
                  </Box>
                  </div>
                  </>
                  ) : (
                    <div>
                      <h3>Sale has not started yet.</h3>
                    </div>
                  )
                  }


                  <br></br>
                  <br></br>


                  {data.step == 1 && WL ?
                    <div>
           
                      <button className="mintbutton" onClick={whitelistmint}>MINT</button>
                    </div>
                    : <div></div>
                  }
                  {data.step == 1 && !WL ?
                    <div>
                      <p className="count">You are not whitelisted.</p>
                    </div> : <div></div>
                  }

                  {data.step == 2 && <div>
                  
                    <button className="mintbutton" onClick={mint}>MINT</button>
                  </div>}



                </div>
              )}
            </div>

            {errCode == 1 &&

              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  You rejected the transaction. Try minting again.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>

                </Modal.Footer>
              </Modal>

            }
            {errCode == 2 &&

              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  You are trying to mint more than the allocated amount for your wallet during this sale.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>

                </Modal.Footer>
              </Modal>

            }
            {errCode == 3 &&

              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Not enough funds.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>

                </Modal.Footer>
              </Modal>

            }

          </div>

        </div>

      </section>

    </main>
  )
}

export default MintPage
